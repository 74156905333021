import React, { Component, ReactNode } from 'react';
import Router, { withRouter } from 'next/router';
import Tealium, { EventLog, HeartBeat } from '@4tn/webx-analytics';
import { setPlayerPositionMutation, setActiveVideoMutation } from 'queries/continueWatching/continueWatchingActions';
import { isVideoCompleted, setAdIdCookie } from 'lib/helpers';
import { IPlayerContainerProps } from 'typings/IPlayerContainerProps';
import VideoModel from 'models/video.model';
import { customInteraction, noticeError, noticePlayerError, setCustomAttributes } from 'lib/newrelic';
import isSearchBot from 'lib/isSearchBot';
import { PAUSE_PLAYER_EVENT } from 'lib/pausePlayer';
import { ContinueWatchingUpdateProgressMutation } from 'queries/continueWatching/continueWatchingQuery.query';
import getVideoErrorDetails from 'lib/helpers/getVideoErrorDetails';
import { trackClick } from 'lib/tealium';
import {
  ACCOUNT,
  FEATURE_SLUG,
  IS_CLIENT,
  PLAYER_MESSAGES,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL,
  TEALIUM_EVENT_NAME
} from '../../constants';
import VideoUnavailable from './VideoUnavailable';
import VideoJWPlayer from './VideoJWPlayer';
import VideoPlaceholder from './VideoPlaceholder';
import { PlayerContainerStyle, PlayerDiv } from './PlayerContainer.style';
import PlaylistControls from './PlaylistControls/PlaylistControls';
import featureTooling from '../FeatureTooling';

const ELEVEN_SECONDS = 11;

class PlayerContainer extends Component<IPlayerContainerProps, IPlayerContainerState> {
  IsFirstPlay: boolean = false;
  playlistControls: PlaylistControls | null = null;

  // eslint-disable-next-line react/state-in-constructor
  state: IPlayerContainerState = {
    isFirstPlay: false,
    isCompletedHeartBeat: false,
    isMuted: false,
    playerError: null,
    noAutoPlay: false
  };

  componentDidMount(): void {
    if (IS_CLIENT) {
      const { client, viewMode, playlistItem } = this.props;
      VideoJWPlayer.initPlayer(client, viewMode);
      setCustomAttributes({ media_id: playlistItem.guid || '' });
    }
    this.setCallbacks();
    this.attemptSetPlaylist();
    Router.events.on('routeChangeStart', this.writeVideoInLocalState);
    window.addEventListener(PAUSE_PLAYER_EVENT, this.onPausePlayerEvent);
  }

  componentDidUpdate(prevProps: Readonly<IPlayerContainerProps>): void {
    const { playlistItem } = this.props;

    if (playlistItem.guid && playlistItem.guid !== prevProps.playlistItem.guid) {
      this.trackStopEvent();
      setCustomAttributes({ media_id: playlistItem.guid });
      this.attemptSetPlaylist();
    }
  }

  componentWillUnmount(): void {
    this.trackStopEvent();
    VideoJWPlayer.stop();
    HeartBeat.stop();
    VideoJWPlayer.resetCallbacks();
    Router.events.off('routeChangeStart', this.writeVideoInLocalState);
    window.removeEventListener(PAUSE_PLAYER_EVENT, this.onPausePlayerEvent);
    setCustomAttributes({ media_id: null, media_name: null, media_type: null });
  }

  onPausePlayerEvent = () => {
    try {
      VideoJWPlayer.pause();
    } catch (ignore) {}
  };

  trackStopEvent = () => {
    const trackData = [...EventLog.eventLogExtended].reverse().find(event => event.call_type === 'video');

    if (!trackData) return;

    Tealium.video({
      ...trackData,
      event_label: null,
      event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
      event_name: TEALIUM_EVENT_NAME.PLAYER_STOP
    });
  };

  attemptSetPlaylist = async (): Promise<void> => {
    const { playlistItem } = this.props;

    if (this.state.playerError) {
      this.setState({ playerError: null });
    }

    if ((isSearchBot() || VideoJWPlayer.userInteracted) && playlistItem.guid !== null) {
      this.setState({ noAutoPlay: false });

      VideoJWPlayer.load(playlistItem);
    } else {
      this.setState({ noAutoPlay: true });
    }
  };

  setPlaylist = async (): Promise<void> => {
    const { playlistItem } = this.props;
    this.setState({ noAutoPlay: false });
    if (playlistItem.guid !== null) {
      VideoJWPlayer.load(playlistItem);
    }
  };
  setCallbacks = (): void => {
    VideoJWPlayer.setOnHeartBeatCallback(this.onHeartBeat);
    VideoJWPlayer.setOnReadyCallback(this.onReady);
    VideoJWPlayer.setOnPlayCallback(this.onPlay);
    VideoJWPlayer.setOnTimeCallback(this.onTime);
    VideoJWPlayer.setOnSeekedCallback(this.onSeeked);
    VideoJWPlayer.setOnPauseCallback(this.onPause);
    VideoJWPlayer.setOnFirstFrameCallback(this.onFirstFrame);
    VideoJWPlayer.setOnCompleteCallback(this.onComplete);
    VideoJWPlayer.setOnPlaylistItemCallback(this.onPlaylistItem);
    VideoJWPlayer.setStopHeartBeatCallback(this.stopHeartBeat);
    VideoJWPlayer.setOnMuteCallback(this.onMute);
    VideoJWPlayer.setOnAdBreakEndCallback(this.onAdBreakEnd);
    VideoJWPlayer.setOnAdCompletedCallback(event => this.onAdCompleted(event));
    VideoJWPlayer.setOnPlayerErrorCallback(this.onError);
    VideoJWPlayer.setOnPlayerWarningCallback(this.onWarning);
    VideoJWPlayer.setOnCastCallback(this.onCast);
    VideoJWPlayer.setOnSourcesErrorCallback(this.onSourcesError);
    VideoJWPlayer.setOnSetupError(this.onError);
  };

  onMute = (): void => {
    if (!VideoJWPlayer.getContainer()?.classList?.contains?.('jw-flag-ads')) {
      this.setState({ isMuted: VideoJWPlayer.getMute() });
    }
  };

  onAdBreakEnd = (): void => {
    if (this.state.isMuted) {
      VideoJWPlayer.setMute?.(true);
    }
  };

  onAdCompleted = (event: IAdCompleteEvent): void => {
    customInteraction('Ad watched');
    setAdIdCookie(event.id);
  };

  writeVideoInLocalState = (): void => {
    const Duration = VideoJWPlayer.getDuration();

    if (Duration && Duration > 0) {
      setPlayerPositionMutation(this.props.client);
      this.onHeartBeat();
    }
  };

  /**
   * This method gets called any time a video gets played
   * The logic inside this method is mainly used when next/previous buttons
   * are being pressed inside the player
   * Since this is called any time a player plays an item, we return from this method
   * if we clicked on an item in the playlist.
   * If that is not the case, this method finds out what page we are on,
   * forms the parameters needed for a route, and pushes the new route
   * @param event
   */
  onPlaylistItem = ({ item }: { item: VideoModel }) => {
    const { playlistItem, client, collectionSlug, availableSeasons } = this.props;

    this.playlistControls = new PlaylistControls({
      client,
      playlistItem,
      collectionSlug,
      push: Router.push,
      jwplayer: VideoJWPlayer.playerInstance as jwplayer.JWPlayer,
      availableSeasons
    });
    const { guid, path, series, sourceProgram, metadata } = item || {};

    setCustomAttributes({ media_name: metadata.media_name || '', media_type: item.type });
    if (metadata.media_duration === null) {
      noticeError('Missing duration');
    }
    try {
      // Check if the correct video is playing
      if (guid !== playlistItem.guid) {
        noticeError('Wrong video playing');
      }
    } catch (ignore) {}
    if (sourceProgram && featureTooling.isFeatureEnabled(FEATURE_SLUG.WATCH_FULL_EPISODE_WEB)) {
      this.addFullEpisodeButton(series.slug, sourceProgram, metadata);
    }

    if (playlistItem.guid === guid || !playlistItem.guid) return;

    Router.push(path || `/programmas/${series.slug}/${guid}`);
  };

  onReady = (): void => {
    customInteraction('Player Ready');
  };

  onTime = (timeData?: ITimeJWplayerData): void => {
    const { isFirstPlay } = this.state;
    const Position: number | null = timeData && timeData.currentTime ? Math.ceil(timeData.currentTime) : null;

    if (Position && (isFirstPlay || Position % 10 === 0)) {
      this.handleCurrentPosition();
    }
  };

  onSeeked = () => {
    this.handleCurrentPosition(false, true);
    this.onHeartBeat();
  };

  onPause = () => {
    this.handleCurrentPosition();
    this.IsFirstPlay = false;
    this.stopHeartBeat();
  };

  onPlay = () => {
    if (!this.IsFirstPlay) {
      this.IsFirstPlay = true;
      const currentPlaylistItem = VideoJWPlayer.getPlaylistItem();
      if (currentPlaylistItem && currentPlaylistItem.guid) {
        this.startHeartBeat();
      }
    }
  };

  onComplete = () => {
    if (this.playlistControls) this.playlistControls.autoplayNextItem();
    this.IsFirstPlay = false;
    this.handleCurrentPosition(true);
    this.stopHeartBeat();
  };

  onFirstFrame = () => {
    this.startHeartBeat();
    customInteraction('Stream start');
    this.setState({
      isFirstPlay: true,
      isCompletedHeartBeat: false
    });
  };

  handleCurrentPosition = (isCompleted?: boolean, isSeeked?: boolean): void => {
    const data = this.getTimeData(isCompleted);
    data && setActiveVideoMutation(this.props.client, data);
    if (data && data.duration - data.position <= ELEVEN_SECONDS && !isCompleted) this.playlistControls?.addNextUpCard();
    else if (isSeeked) this.playlistControls?.removeNextUpCard();
    setPlayerPositionMutation(this.props.client);
  };

  onHeartBeat = (): void => {
    const isAuthenticated = !!localStorage.getItem(ACCOUNT.AUTH_TOKEN);
    const { isCompletedHeartBeat } = this.state;
    const PlaylistItem = VideoJWPlayer.getPlaylistItem();
    const { guid, duration } = PlaylistItem || {};
    const VideoTimeData = this.getTimeData();
    const IsVideoCompleted = isVideoCompleted(VideoTimeData?.position, VideoTimeData?.duration);

    if (!guid || (IsVideoCompleted && isCompletedHeartBeat)) {
      HeartBeat.stop();
      return;
    }

    if (isAuthenticated && VideoTimeData && VideoTimeData.position !== 0 && duration) {
      this.setVideoCompletedAndSendHeartBeat(IsVideoCompleted, guid, VideoTimeData);
    }
  };

  onCast = (event: any): void => {
    // tslint:disable-next-line:no-console
    console.log('%cCast event:', 'color:red', event);
  };

  onError = (event: IPlayerErrorEvent) => {
    const OFFLINE_ERROR_CODES = [230002, 334001];

    this.stopHeartBeat();
    const playlistItemMetadata = VideoJWPlayer.playerInstance?.getPlaylistItem()?.metadata;
    noticePlayerError(event, playlistItemMetadata);

    if (OFFLINE_ERROR_CODES.includes(event.code)) return;

    if (`${event.code}`.match(/^(1|2)/)) {
      const playerError = getVideoErrorDetails(event.code, this.props.playlistItem);
      this.setState(prevState => ({ playerError: playerError || prevState.playerError }));
    }
  };

  onWarning = (event: IPlayerErrorEvent) => {
    const playlistItemMetadata = VideoJWPlayer.playerInstance?.getPlaylistItem()?.metadata;
    noticePlayerError(event, playlistItemMetadata);
  };

  onSourcesError = () => {
    this.setState({ playerError: PLAYER_MESSAGES.NO_PLAYABLE_SOURCES_FOUND });
  };

  setVideoCompletedAndSendHeartBeat = (isVideoCompleted: boolean, guid: string, VideoTimeData: IVideo): void => {
    const { isCompletedHeartBeat } = this.state;
    if (isVideoCompleted !== isCompletedHeartBeat) {
      this.setState({ isCompletedHeartBeat: isVideoCompleted });
    }
    this.sendHeartBeat(guid, VideoTimeData.position, isVideoCompleted);
  };

  sendHeartBeat = (guid: string, position: number, completed: boolean = false): void => {
    const isAuthenticated = !!localStorage.getItem(ACCOUNT.AUTH_TOKEN);
    if (!isAuthenticated) return;
    this.props.client.mutate({
      mutation: ContinueWatchingUpdateProgressMutation,
      variables: {
        guid,
        completed,
        position
      }
    });
  };

  getTimeData = (isCompleted?: boolean): IVideo | null => {
    const position = VideoJWPlayer.getCurrentTime() || 0;
    const duration = VideoJWPlayer.getDuration() || 0;
    const completed = isCompleted || isVideoCompleted(position, duration);
    const guid = VideoJWPlayer.getGuid();
    if (guid) {
      return {
        position,
        duration,
        completed,
        guid
      };
    }
    return null;
  };

  startHeartBeat = (): void => {
    if (HeartBeat.state === 'idle') HeartBeat.start();
  };

  stopHeartBeat = () => {
    this.onHeartBeat();
    HeartBeat.stop();
  };

  reloadPlayer = () => {
    this.setState({ playerError: null });
    VideoJWPlayer.onSetup();
  };

  addFullEpisodeButton(
    seriesSlug: string,
    sourceProgram: string,
    metadata: { [key: string]: string | number | boolean | null }
  ): void {
    const EPISODE_BUTTON_TEXT = 'Kijk de hele aflevering';
    const container = VideoJWPlayer.getContainer();

    if (!container) return;

    document.querySelectorAll('.full-episode-button').forEach(element => element.remove());
    const button = document.createElement('button');
    button.className = 'full-episode-button';
    button.onclick = () => {
      trackClick({
        name: TEALIUM_EVENT_NAME.PLAYER_CLICK,
        category: TEALIUM_EVENT_CATEGORY.PLAYER,
        label: TEALIUM_EVENT_LABEL.FULL_EPISODE,
        defaultValues: metadata
      });
      Router.push(`/programmas/${seriesSlug}/${sourceProgram}`);
    };

    const buttonText = document.createElement('span');
    buttonText.innerText = EPISODE_BUTTON_TEXT;
    button.appendChild(buttonText);
    container.appendChild(button);
  }

  render(): ReactNode {
    const { playlistItem } = this.props;
    const { playerError, noAutoPlay } = this.state;
    return (
      <PlayerContainerStyle data-testid="playerContainerStyle">
        {playerError ? (
          <VideoUnavailable handleClick={this.reloadPlayer} {...playerError} />
        ) : (
          <PlayerDiv>
            <div className="player-container" />
          </PlayerDiv>
        )}
        {noAutoPlay && <VideoPlaceholder handleClick={this.setPlaylist} image={playlistItem?.image} />}
      </PlayerContainerStyle>
    );
  }
}

export default withRouter(PlayerContainer as any);
